import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Pagination",
  "type": "Navigation"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`Pagination`}</strong>{` is used in a combination with `}<a parentName="p" {...{
        "href": "/content/table"
      }}><strong parentName="a">{`Table`}</strong></a>{`. It renders
buttons, representing the pages available as well as the current page.`}</p>
    <hr></hr>
    <h2>{`Example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true editor=false center=true background=layout",
        "live": "true",
        "editor": "false",
        "center": "true",
        "background": "layout"
      }}>{`<Pagination pages={10} page={2} />
`}</code></pre>
    <h2>{`Props`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional class name that will be set`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`page`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`( `}<strong parentName="td">{`Required`}</strong>{` ) The current selected page`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`pages`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`( `}<strong parentName="td">{`Required`}</strong>{` ) The amount of pages`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`size`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`7`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The amount of buttons rendered ( minimum is `}<inlineCode parentName="td">{`7`}</inlineCode>{` )`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`onChange`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`( pageClicked : number ) => void`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Callback when a page was clicked`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Size`}</h2>
    <p><strong parentName="p">{`Pagination`}</strong>{` by default renders with 7 buttons. 2 are always static showing the button
for the previous page and the one for the next page. The middle shows always the first
page, and the last page number and in between the rest of the pages including the button
for more pages.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true editor=true background=layout",
        "live": "true",
        "editor": "true",
        "background": "layout"
      }}>{`<>
  <div style={{ display: "flex", justifyContent: "center" }}>
    <Pagination pages={11} page={2} size={11} />
  </div>
  <Separator />
  <div style={{ display: "flex", justifyContent: "center" }}>
    <Pagination pages={50} page={2} size={11} />
  </div>
  <Separator />
  <div style={{ display: "flex", justifyContent: "center" }}>
    <Pagination pages={50} page={25} size={11} />
  </div>
  <Separator />
  <div style={{ display: "flex", justifyContent: "center" }}>
    <Pagination pages={50} page={48} size={11} />
  </div>
</>
`}</code></pre>
    <h2>{`Event handling`}</h2>
    <p><strong parentName="p">{`Pagination`}</strong>{` component provides a convenient `}<inlineCode parentName="p">{`onChange`}</inlineCode>{` handler that can be used to
change the current page selected by the user.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true editor=true background=layout center=true",
        "live": "true",
        "editor": "true",
        "background": "layout",
        "center": "true"
      }}>{`() => {
    const [page, setPage] = React.useState(1);
    const handleChange = React.useCallback( selectedPage => {
        setPage( selectedPage );
    }, []);
    return <Pagination pages={50} page={page} size={11} onChange={handleChange} />
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      